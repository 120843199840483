<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
    <!-- 新增和单独编辑对话框 -->
    <c-dialog :title="title" :width="700" :showDialog.sync="dialogFormVisible">
      <template #content>
        <el-form :model="form" :rules="rules" ref="ruleForm" v-loading="dialogLoading" label-width="100px">
          <el-form-item label="次卡编码" prop="timesCardNo">
            <el-input maxlength="20" v-model="form.timesCardNo" placeholder="请输入次卡编码" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="次卡名称" prop="timesCardName">
            <el-input maxlength="50" v-model="form.timesCardName" placeholder="请输入次卡名称" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="次卡类型" prop="timesCardType" size="mini">
            <el-radio-group v-model="form.timesCardType">
              <el-radio :label="1">商品次卡</el-radio>
              <el-radio :label="2">套餐次卡</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="x-f" v-if="form.timesCardType == 1">
            <el-form-item label="商品" prop="goodsId">
              <SelectRemote @selectChange="selectGoods" v-model="form.goodsId" :option="
                  $select({
                    key: 'listGoods',
                    option: {
                      option: {
                        clearable: true,
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择商品',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: $dialog({
                                key: 'goods',
                                option: {
                                  table: { 
                                     ...$dialog({ key: 'goods' }).table,
                                    mutiSelect: false,
                                    radioSelect: true,
                                  },
                                },
                              }),
                            },
                          },
                        ],
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="单位" prop="unitId">
              <SelectLocal v-model="form.unitId" :option="{
                  data: form.units,
                  value: 'unitId',
                  label: 'unitName',
                }" />
            </el-form-item>
          </div>
          <div class="x-f" v-else>
            <el-form-item label="促销方案" prop="promoCaseBillId">
              <SelectRemote @selectChange="selectCase" v-model="form.promoCaseBillId" :option="
                  $select({
                    key: 'listPromo',
                    option: {
                      option: {
                        clearable: true,
                        remoteBody: {
                          promoTypes: [10],
                          executionStatuss: [1, 2],
                          isStops: [false],
                        },
                      },
                    },
                  }).option
                " />
            </el-form-item>
          </div>
          <el-form-item label="可用次数" prop="totalCount">
            <el-input maxlength="4" v-model="form.totalCount" placeholder="请输入可用次数" size="mini"
              type="number"></el-input>
          </el-form-item>
          <el-form-item label="销售价格" prop="salePrice">
            <el-input maxlength="8" v-model="form.salePrice" placeholder="请输入销售价格" size="mini"
              type="number"></el-input>
          </el-form-item>
          <el-form-item label="*有效时间" prop="limitVaildTimeType">
            <el-radio-group v-model="form.limitVaildTimeType" @change="limitVaildTimeChange">
              <el-radio :label="0">不限制</el-radio>
              <el-radio :label="1">限制使用天数</el-radio>
              <el-radio :label="2">固定使用日期</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="form.limitVaildTimeType == 1">
         <el-form-item label="有效天数" prop="limitDays">
            <el-input maxlength="4" v-model="form.limitDays" placeholder="请输入有效天数" size="mini"
              type="number"></el-input>
          </el-form-item>
          </div>
          <el-form-item v-if="form.limitVaildTimeType == 2" label="*有效日期">
            <el-date-picker unlink-panels size="mini" v-model="datetime" type="daterange" start-placeholder="开始日期"
              end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="disableOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="使用限制" prop="limitUseCountType" size="mini">
            <el-radio-group v-model="form.limitUseCountType">
              <el-radio :label="0">不限制</el-radio>
              <el-radio :label="1">每日限制次数</el-radio>
              <el-radio :label="2">每周限制次数</el-radio>
              <el-radio :label="3">每月限制次数</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.limitUseCountType != 0" label="限制次数" prop="limitCount">
            <el-input maxlength="4" v-model="form.limitCount" placeholder="请输入限制次数" size="mini"
              type="number"></el-input>
          </el-form-item>
          <el-form-item label="是否门店销售" prop="isAllowOfflineShop" size="mini" label-width="120px">
            <el-radio-group v-model="form.isAllowOfflineShop">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否线上销售" prop="isAllowOnlineShop" size="mini" label-width="120px">
            <el-radio-group v-model="form.isAllowOnlineShop">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="form.remark" type="textarea" placeholder="备注长度介于 1 和 80 字符之间" maxlength="80"
              :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="handSubmit">确 定</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  timesCardListApi,
  timesCardSaveApi,
  timesCardRemoveApi,
  timesCardDetailApi,
  timesCardUpdateApi,
} from "@/api/marketing/timesCard/manage";
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import SelectRemote from "@/components/tablePage/select/select-remote";
import SelectLocal from "@/components/tablePage/select/select-local";
export default {
  name: "Manage",
  components: { TablePage, CDialog, SelectRemote, SelectLocal },
  data() {
    return {
     disableOptions: {
        disabledDate(time) {
          // 8.64e7 毫秒数代表一天
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      datetime: undefined, //有效日期
      rules: {
        timesCardNo: [
          {
            required: true,
            message: "次卡编码不能为空",
            trigger: ["blur", "change"],
          }
        ],
        timesCardName: [
          {
            required: true,
            message: "次卡名称不能为空",
            trigger: ["blur", "change"],
          },
        ],
        timesCardType: [
          {
            required: true,
            message: "次卡类型不能为空",
            trigger: ["blur", "change"],
          },
        ],
        goodsId: [
          {
            required: true,
            message: "商品不能为空",
            trigger: ["blur", "change"],
          },
        ],
        unitId: [
          {
            required: true,
            message: "商品单位不能为空",
            trigger: ["blur", "change"],
          },
        ],
        promoCaseBillId: [
          {
            required: true,
            message: "促销方案不能为空",
            trigger: ["blur", "change"],
          },
        ],
        totalCount: [
          {
            required: true,
            message: "可用次数不能为空",
            trigger: ["blur", "change"],
          },
        ],
        salePrice: [
          {
            required: true,
            message: "销售价格不能为空",
            trigger: ["blur", "change"],
          },
        ],
        limitVaildTimeType: [
          {
            required: true,
            message: "有效时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
        limitDays: [
          {
            required: true,
            message: "有效天数不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // timeDate: [
        //   {
        //     required: true,
        //     message: "有效日期不能为空",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        limitUseCountType: [
          {
            required: true,
            message: "使用限制不能为空",
            trigger: ["blur", "change"],
          },
        ],
        limitCount: [
          {
            required: true,
            message: "限制次数不能为空",
            trigger: ["blur", "change"],
          },
        ],
        isAllowOfflineShop: [
          {
            required: true,
            message: "是否门店销售不能为空",
            trigger: ["blur", "change"],
          },
        ],
        isAllowOnlineShop: [
          {
            required: true,
            message: "是否线上销售不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      form: {
        units: [],
        timesCardType: 1,
        limitVaildTimeType: 0,
        limitUseCountType: 0,
        isAllowOfflineShop: 1,
        isAllowOnlineShop: 1,
      },
      dialogLoading: false,
      dialogFormVisible: false,
      title: "新增次卡",
      options: {
        mutiSelect: true,
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "次卡管理",
        getListApi: timesCardListApi,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "timesCardId",
        //搜索
        search: [
          {
            type: "filters",
            tip: "次卡编码/次卡名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "timesCardNos", label: "次卡编码" },
              { filter: "timesCardNames", label: "次卡名称" },
            ],
          },
          {
            type: "button",
            tip: "查询",
            btnType: "primary",
            click: "search",
            isRestore: true,
          },
        ],
        //按钮
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            alertText: "确认要删除选中的次卡吗？",
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "timesCardNo",
            label: "次卡编码",
            type: "link",
            click: "update",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "timesCardName",
            label: "次卡名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "timesCardType",
            label: "次卡类型",
            minWidth: 120,
            align: "center",
            formatter: (propValue, row) => {
              return row.timesCardType == 1
                ? "商品次卡"
                : row.timesCardType == 2
                  ? "套餐次卡"
                  : "";
            },
          },
          {
            prop: "goodsName",
            label: "商品",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "totalCount",
            label: "可用次数",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "salePrice",
            label: "销售价格",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "limitVaildTimeType",
            label: "有效时间",
            minWidth: 155,
            align: "center",
            formatter: (propValue, row) => {
              return row.limitVaildTimeType == 0
                ? "不限制"
                : row.limitVaildTimeType == 1
                  ? `限制使用天数(${row.limitDays})`
                  : row.limitVaildTimeType == 2
                    ? `固定使用日期(${row.limitBegDate + "-" + row.limitEndDate})`
                    : "";
            },
          },
          {
            prop: "limitUseCountType",
            label: "使用限制",
            minWidth: 120,
            align: "center",
            formatter: (propValue, row) => {
              return row.limitUseCountType == 0
                ? "不限制"
                : row.limitUseCountType == 1
                  ? "每日限制次数"
                  : row.limitUseCountType == 2
                    ? "每周限制次数"
                    : row.limitUseCountType == 3
                      ? "每月限制次数"
                      : "";
            },
          },
          {
            prop: "isAllowOfflineShop",
            label: "是否门店销售",
            minWidth: 100,
            align: "center",
            formatter: (propValue, row) => {
              return row.isAllowOfflineShop == 0
                ? "否"
                : row.isAllowOfflineShop == 1
                  ? "是"
                  : "";
            },
          },
          {
            prop: "isAllowOnlineShop",
            label: "是否线上销售",
            minWidth: 100,
            align: "center",
            formatter: (propValue, row) => {
              return row.isAllowOnlineShop == 0
                ? "否"
                : row.isAllowOnlineShop == 1
                  ? "是"
                  : "";
            },
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 130,
            align: "center",
          },
        ],
        list: [],
      },
    };
  },
  watch: {
    //单据日期
    datetime(newVal) {
      if (newVal) {
        this.form.limitBegDate = newVal[0];
        this.form.limitEndDate = newVal[1];
      } else {
        this.form.limitBegDate = undefined;
        this.form.limitEndDate = undefined;
      }
    },
  },
  methods: {
    limitVaildTimeChange(){
      this.$refs.ruleForm.clearValidate('limitDays')
    },
    //新增、修改对话框确认按钮
    async handSubmit() {
      try {
        if (this.form.limitVaildTimeType == 2 && !this.form.limitBegDate) return this.$message.error("请选择有效日期！");
        await this.$refs["ruleForm"].validate();
        if (this.form.timesCardId == null) {
          await timesCardSaveApi(this.form);
          this.$message({
            message: "新增次卡成功",
            type: "success",
          });
        } else {
          await timesCardUpdateApi(this.form);
          this.$message({
            message: "修改次卡成功",
            type: "success",
          });
        }
        this.dialogFormVisible = false;
        this.reset(); //对话框重置
        await this.$refs.tablePage.getList();
      } catch (error) { }
    },
    //新增取消
    cancel() {
      this.reset();
      this.dialogFormVisible = false;
    },
    // 表单重置
    reset() {
      this.form = {
        units: [],
        timesCardType: 1,
        limitVaildTimeType: 0,
        limitUseCountType: 0,
        isAllowOfflineShop: 1,
        isAllowOnlineShop: 1,
      };
        this.datetime = undefined,
        this.resetForm("ruleForm");
    },
    async handleEvent(type, row) {
      switch (type) {
        case 'dialogChange':
          console.log('dialogChange', type, row);
          break;
        case "add":
          this.reset();
          this.dialogFormVisible = true;
          break;
        case "update":
          this.title='编辑次卡'
          this.dialogFormVisible = true;
          const res = await timesCardDetailApi(row.timesCardId);
          this.form = res.data;
          if (this.form.limitVaildTimeType==2) {
            this.datetime=[this.form.limitBegDate, this.form.limitEndDate]
          }
          break;
        case "del":
          try {
            this.options.loading = true;
            await timesCardRemoveApi(
              this.options.check.map((item) => item.timesCardId).filter(x => x != undefined),
            );
            await this.$refs.tablePage.getList();
            this.$message.success("删除成功");
          } catch (error) {
          } finally {
            this.options.loading = false;
          }
          break;
        default:
          break;
      }
    },
    selectCase(value, item) {
      this.form.promoCaseBillName = item.promoCaseBillName;
    },
    selectGoods(value, item) {
      this.$set(this.form, "units", item.units);
      this.$set(this.form, "unitId", 0);
    },
  },
};
</script>

<style lang="scss" scoped>
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
